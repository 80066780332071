import { splitsList } from '../../../constants';

const coreDefault = 'off';

const splitNames = Object.values(splitsList);

const defaultTestConfiguration = {
  default: 'off',
  111111111111: 'on',
};

const map = {
  [splitsList.boostOnline]: {
    default: 'off',
    555555555555: 'beta',
    147125823693: 'beta',
  },
  [splitsList.boostPlacement]: {
    default: 'off',
    10000000001: 'high-intent-day0-a',
    10000000002: 'high-intent-day1plus-a',
    10000000003: '999ers-day1plus-b',
    10000000004: '999ers-day0-b',
    10000000005: 'low-intent-day1plus-b',
    10000000006: 'low-intent-day0-b',
    10000000007: 'high-intent-day1plus-b',
    10000000008: 'high-intent-day0-b',
    10000000009: 'low-intent-day1plus-c',
    10000000010: 'low-intent-day0-c',
    10000000011: 'high-intent-day1plus-c',
    10000000012: 'high-intent-day0-c',
    10000000013: 'low-intent-day0-a',
    10000000014: 'low-intent-day1plus-a',
    10000000015: '999ers-day0-a',
    10000000016: '999ers-day1plus-a',
    10000000017: 'disabled',
    10000000018: 'high-intent-day0-a',
    10000000019: 'low-intent-day1plus-c',
    10000000020: '999ers-day0-b',
  },
  [splitsList.breachPlus]: defaultTestConfiguration,
  [splitsList.creditLock]: {
    111111111111: 'on',
  },
  [splitsList.creditLockWeb]: {
    default: 'on',
    112211221122: 'off',
    112211221133: 'on',
  },
  [splitsList.creditLockChurnTest]: {
    default: 'on',
    112211221122: 'on',
    112211221133: 'off',
  },
  [splitsList.covidResponse]: {
    default: 'off',
    111111111111: 'on',
    anonymous: 'on',
  },
  [splitsList.loginSuccessEvents]: {
    default: 'off',
    111111111111: 'on',
  },
  [splitsList.scoreComponents]: {
    default: 'off',
    222222222222: 'on',
    444444444444: 'on',
    555555555555: 'on',
    10000000001: 'on',
    10000000002: 'on',
    10000000003: 'on',
    10000000004: 'on',
    10000000005: 'on',
    10000000006: 'on',
    10000000007: 'on',
    10000000008: 'on',
    10000000009: 'on',
    10000000010: 'on',
    10000000011: 'on',
    10000000012: 'on',
    10000000013: 'on',
    10000000014: 'on',
    10000000015: 'on',
    10000000016: 'on',
    10000000017: 'on',
  },
  [splitsList.scoreHistory]: {
    default: 'off',
    333333333333: 'on',
    444444444444: 'on',
    555555555555: 'on',
    10000000001: 'on',
    10000000002: 'on',
    10000000003: 'on',
    10000000004: 'on',
    10000000005: 'on',
    10000000006: 'on',
    10000000007: 'on',
    10000000008: 'on',
    10000000009: 'on',
    10000000010: 'on',
    10000000011: 'on',
    10000000012: 'on',
    10000000013: 'on',
    10000000014: 'on',
    10000000015: 'on',
    10000000016: 'on',
    10000000017: 'on',
  },
  [splitsList.showRecycleBanner]: defaultTestConfiguration,
  [splitsList.showFullCreditReportIDPlus]: defaultTestConfiguration,
  [splitsList.showFullCreditReportBreachPlus]: defaultTestConfiguration,
  [splitsList.showFullCreditReportBreach]: defaultTestConfiguration,
  [splitsList.identityStoppedApplicationAlert]: {
    default: 'off',
    10000000101: 'Option1',
    10000000102: 'Option2',
  },
  'current-account-navigation': {
    default: 'on',
  },
  'savings-account-navigation': {
    default: 'on',
  },
  [splitsList.protectWebRollout]: {
    default: 'off',
    10000000000: 'protect_control',
    110435826712: 'off',
  },
};

export default (customerNumber, splits = splitNames, splitMap = map) =>
  splits.reduce((_splits, split) => {
    const mappedSplits = _splits;
    if (splitMap[split] && splitMap[split][customerNumber]) {
      mappedSplits[split] = splitMap[split][customerNumber];
    } else {
      mappedSplits[split] = splitMap[split] ? splitMap[split].default || coreDefault : coreDefault;
    }
    return mappedSplits;
  }, {});

import { SPLIT_KEY_FETCHED } from '../actions/auth/getCustomer';
import { SPLIT_CLIENT_FAILED, SPLIT_CLIENT_READY, SPLITS_FETCHED } from '../actions/split';
import splitTreatments from '../constants/splitTreatments';

export const errors = {
  timeout: 'Split initialisation timed out.',
};

export const getSplitStatus = (state = { treatments: {} }, splitsToGet = []) =>
  Object.keys(state.treatments)
    .filter(treatment => splitsToGet.includes(treatment))
    .reduce((treatments, key) => {
      const selectedTreatments = treatments;
      selectedTreatments[key] = state.treatments[key];
      return selectedTreatments;
    }, {});

export const getSplitConfig = (state, splitsToGet = []) =>
  state?.split?.configs
    ? Object.keys(state.split.configs)
        .filter(splitName => splitsToGet.includes(splitName))
        .reduce(
          (configs, splitName) => ({
            ...configs,
            [splitName]: state.split.configs[splitName],
          }),
          {}
        )
    : {};

const initialState = {
  error: null,
  ready: false,
  treatments: {},
  configs: {},
  rawTreatments: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SPLIT_KEY_FETCHED:
      return {
        ...state,
        key: action.payload,
      };
    case SPLIT_CLIENT_FAILED:
      return {
        ...state,
        error: errors.timeout,
        ready: false,
      };
    case SPLIT_CLIENT_READY:
      return {
        ...state,
        error: null,
        ready: true,
      };
    case SPLITS_FETCHED:
      return {
        ...state,
        treatments: Object.keys(action.payload).reduce((treatmentMap, key) => {
          const treatments = treatmentMap;
          let splitValue = action.payload[key];
          if ([splitTreatments.off, splitTreatments.control].includes(splitValue.treatment)) {
            splitValue = false;
          } else if (
            splitValue.treatment === splitTreatments.on ||
            splitValue.treatment === splitTreatments.protectControl
          ) {
            splitValue = true;
          } else splitValue = splitValue.treatment;

          treatments[key] = splitValue;
          return treatments;
        }, {}),
        configs: Object.keys(action.payload).reduce((treatmentMap, key) => {
          const treatments = treatmentMap;
          const { config } = action.payload[key];
          if (config) treatments[key] = config;
          return treatments;
        }, {}),
        rawTreatments: action.payload,
      };
    default:
      return state;
  }
};
